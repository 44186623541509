<template>
	<div class="theme-dashboard-view">
		<h1>{{ $t("settings_title") }}</h1>
		<!-- {{ settings }} -->
		<div class="shadow-sm p-4" style="min-height: 550px">
			<div class="p-3">
				<h4>{{ $t("settings_general_title") }}</h4>
			</div>
			<!-- Admin First Name -->
			<div class="d-flex p-4">
				<div class="col-sm-6 col-md-4 col-lg-3">
					<span class="p-2" style="font-size: 20px">
						<i class="fa fa-user" aria-hidden="true"></i>
					</span>

					<span class="mx-2">
						{{ $t("settings_general_firstName") }}
					</span>
				</div>

				<span class="col-sm-6 col-md-8 col-lg-9 d-flex">
					<input
						type="text"
						v-model="firstName_new"
						class="admin-field-input p-2"
						:class="{
							'admin-field-change': firstName_new !== firstName,
						}"
					/>
					<button
						class="btn btn-primary mx-2"
						v-show="firstName_new !== firstName"
						@click="
							() => {
								firstName = firstName_new;
								updateAdmin();
							}
						"
					>
						Submit
					</button>
					<button
						class="btn btn-outline-secondary mx-2"
						v-show="firstName_new !== firstName"
						@click="
							() => {
								firstName_new = firstName;
							}
						"
					>
						Cancel
					</button>
				</span>
			</div>

			<!-- Admin Second Name -->
			<div class="d-flex p-4">
				<div class="col-sm-6 col-md-4 col-lg-3">
					<span class="p-2" style="font-size: 20px">
						<i class="fa fa-user" aria-hidden="true"></i>
					</span>

					<span class="mx-2">
						<strong>{{ $t("settings_general_lastName") }}</strong>
					</span>
				</div>

				<span class="col-sm-6 col-md-8 col-lg-9 d-flex">
					<input
						type="text"
						v-model="lastName_new"
						class="admin-field-input p-2"
						:class="{
							'admin-field-change': lastName_new !== lastName,
						}"
					/>
					<button
						class="btn btn-primary mx-2"
						v-show="lastName_new !== lastName"
						@click="
							() => {
								lastName = lastName_new;
								updateAdmin();
							}
						"
					>
						Submit
					</button>
					<button
						class="btn btn-outline-secondary mx-2"
						v-show="lastName_new !== lastName"
						@click="
							() => {
								lastName_new = lastName;
							}
						"
					>
						Cancel
					</button>
				</span>
			</div>

			<!-- Admin Email -->
			<div class="d-flex p-4">
				<div class="col-sm-6 col-md-4 col-lg-3">
					<span class="p-2" style="font-size: 20px">
						<!-- <i class="fa fa-user" aria-hidden="true"></i> -->
						<!-- <i class="fas fa-mail-bulk"></i> -->
						<i class="fa fa-at" aria-hidden="true"></i>
					</span>

					<span class="mx-2">
						<strong>{{ $t("settings_general_email") }}</strong>
					</span>
				</div>

				<span class="col-sm-6 col-md-8 col-lg-9 d-flex">
					<input
						type="text"
						v-model="email_new"
						class="admin-field-input p-2"
						:class="{ 'admin-field-change': email_new != email }"
						autocomplete="false"
						readonly
						onfocus="this.removeAttribute('readonly');"
					/>
					<button
						class="btn btn-primary mx-2"
						v-show="email_new != email"
						@click="
							() => {
								email = email_new;
								updateAdmin();
							}
						"
					>
						Submit
					</button>
					<button
						class="btn btn-outline-secondary mx-2"
						v-show="email_new != email"
						@click="
							() => {
								email_new = email;
							}
						"
					>
						Cancel
					</button>
				</span>
			</div>

			<!-- Admin Password -->
			<div class="d-flex p-4">
				<div class="col-sm-6 col-md-4 col-lg-3">
					<span class="p-2" style="font-size: 20px">
						<i class="fa fa-lock" aria-hidden="true"></i>
					</span>

					<span class="mx-2">
						<strong>{{ $t("settings_general_password") }}</strong>
					</span>
				</div>

				<span class="col-sm-6 col-md-8 col-lg-9">
					<button
						class="btn btn-danger"
						@click="
							() => {
								this.$refs.PasswordChangeForm.showPopup();
							}
						"
					>
						{{ $t("settings_general_change_password_button_text") }}
					</button>
				</span>
			</div>
			<Popup ref="PasswordChangeForm" title="Password Change Form">
				<template v-slot:body>
					<div class="border p-4">
						<div>
							<p style="font-weight: bold">Change Password</p>
						</div>

						<!-- <div class="d-flex justify-content-between my-2">
							<span class="me-4">Old Password</span>
							<span>
								<input type="text" class="p-1" />
							</span>
						</div> -->
						<div class="d-flex justify-content-between my-2">
							<span class="me-4">New Password</span>
							<span>
								<input
									type="password"
									class="p-1"
									v-model="password"
									autocomplete="false"
									readonly
									onfocus="this.removeAttribute('readonly');"
								/>
							</span>
						</div>
					</div>
				</template>

				<template v-slot:footer>
					<div class="d-flex justify-content-center">
						<button class="btn btn-primary" @click="changePassword">
							Submit
						</button>
					</div>
				</template>
			</Popup>

			<div class="d-flex p-4">
				<div class="col-sm-6 col-md-4 col-lg-3">
					<span class="p-2" style="font-size: 20px">
						<i class="fa fa-phone" aria-hidden="true"></i>
					</span>

					<span class="mx-2">
						<strong>{{ $t("settings_general_phoneNumber") }}</strong>
					</span>
				</div>

				<span class="col-sm-6 col-md-8 col-lg-9">
					<input
						type="text"
						v-model="phoneNumber_new"
						class="p-2 admin-field-input"
						:class="{
							'admin-field-change': phoneNumber_new != phoneNumber,
						}"
						autocomplete="false"
						readonly
						onfocus="this.removeAttribute('readonly');"
					/>
					<button
						class="btn btn-primary mx-2"
						v-show="phoneNumber_new != phoneNumber"
						@click="
							() => {
								phoneNumber = phoneNumber_new;
								updateAdmin();
							}
						"
					>
						Submit
					</button>
					<button
						class="btn btn-outline-secondary mx-2"
						v-show="phoneNumber_new != phoneNumber"
						@click="
							() => {
								phoneNumber_new = phoneNumber;
							}
						"
					>
						Cancel
					</button>
				</span>
			</div>

			<!-- Language Change -->
			<div class="d-flex p-4">
				<div class="col-sm-6 col-md-4 col-lg-3">
					<span class="p-2" style="font-size: 20px">
						<i class="fa fa-language" aria-hidden="true"></i>
					</span>

					<span class="mx-2">
						<strong>{{ $t("settings_general_language_title") }}</strong>
					</span>
				</div>
				<span class="col-sm-6 col-md-8 col-lg-9">
					<select
						class="px-3 py-2"
						style="width: 150px"
						v-model="lang_setting"
						@change="languageChange"
					>
						<option value="en">
							{{ $t("settings_general_language_english") }}
						</option>
						<option value="amh">
							{{ $t("settings_general_language_amharic") }}
						</option>
					</select>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

//Componenets
import Popup from "@/components/Popup.vue";

// Services
import AdminService from "@/services/admin.js";

export default {
	name: "Settings",
	data() {
		return {
			lang_setting: "en",

			// Admin User Data
			id: 0,
			email: "",
			firstName: "",
			lastName: "",
			phoneNumber: "",
			dateOfBirth: "",
			password: "",

			// Display Data
			email_new: "",
			firstName_new: "",
			lastName_new: "",
			phoneNumber_new: "",
			dateOfBirth_new: "",
		};
	},
	components: {
		Popup,
	},
	methods: {
		// Change Global Language State
		languageChange() {
			this.$store.dispatch("settings/setLanguage", this.lang_setting);
			this.$i18n.locale = this.lang_setting;
		},

		// Reset the changed values to orignal values
		resetAdminUser() {
			this.email_new = this.email;
			this.firstName_new = this.firstName;
			this.lastName_new = this.lastName;
			this.phoneNumber_new = this.phoneNumber;
			this.dateOfBirth_new = this.dateOfBirth;
		},
		// Update the Admin fields
		updateAdmin() {
			AdminService.updateAdmin(this.id, {
				firstName: this.firstName,
				lastName: this.lastName,
				email: this.email,
				phoneNumber: this.phoneNumber,
			}).then(
				(response) => {
					console.log(response);
				},
				(error) => {
					console.log(error);
				}
			);
		},

		// Change Password
		changePassword() {
			AdminService.changePassword(this.id, this.password).then(
				(response) => {
					console.log(response);
					this.$refs.PasswordChangeForm.closePopup();
				},
				(err) => {
					console.log(err.response);
				}
			);
		},
	},
	computed: {
		...mapGetters({
			settings: "settings/allSettings",
			admin_user: "auth/getUser",
		}),
	},
	mounted() {
		this.lang_setting = this.settings.language;
		console.log(`the admin email is: ${this.admin_user.email}`);
		AdminService.getAdminByEmail().then(
			(response) => {
				const user_info = response.data.data;
				this.id = user_info.id;
				this.email = user_info.email;
				this.firstName = user_info.first_name;
				this.lastName = user_info.last_name;
				this.phoneNumber = user_info.phone_number;
				this.dateOfBirth = user_info.date_of_birth;

				this.resetAdminUser();
			},
			(error) => {
				console.log("ERROR", error);
			}
		);
	},
};
</script>

<style scoped>
/* .admin-field-input {
} */

.admin-field-change {
	background-color: lightblue;
}
</style>
